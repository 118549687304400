export default function SuccessIcon() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="28" fill="#A0D911" />
      <path
        d="M24.7333 32.2L36.0333 20.9C36.3 20.6333 36.6111 20.5 36.9667 20.5C37.3222 20.5 37.6333 20.6333 37.9 20.9C38.1667 21.1667 38.3 21.4836 38.3 21.8507C38.3 22.2178 38.1667 22.5342 37.9 22.8L25.6667 35.0667C25.4 35.3333 25.0889 35.4667 24.7333 35.4667C24.3778 35.4667 24.0667 35.3333 23.8 35.0667L18.0667 29.3333C17.8 29.0667 17.672 28.7502 17.6827 28.384C17.6933 28.0178 17.8324 27.7009 18.1 27.4333C18.3675 27.1658 18.6844 27.0324 19.0507 27.0333C19.4169 27.0342 19.7333 27.1676 20 27.4333L24.7333 32.2Z"
        fill="#080B10"
      />
    </svg>
  );
}
