export default function PhoneIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.7125 19.25C3.4375 19.25 3.20833 19.1583 3.025 18.975C2.84167 18.7917 2.75 18.5625 2.75 18.2875V14.575C2.75 14.3764 2.81875 14.197 2.95625 14.0369C3.09375 13.8768 3.26944 13.7659 3.48333 13.7042L6.64583 13.0625C6.85972 13.0319 7.07758 13.0512 7.29942 13.1202C7.52125 13.1893 7.70061 13.2923 7.8375 13.4292L9.99167 15.5833C10.5722 15.2472 11.1222 14.8766 11.6417 14.4714C12.1611 14.0662 12.6576 13.6272 13.1313 13.1542C13.6354 12.6653 14.0977 12.1574 14.5182 11.6307C14.9386 11.1039 15.3013 10.5576 15.6062 9.99167L13.3833 7.74583C13.2611 7.62361 13.1771 7.47847 13.1313 7.31042C13.0854 7.14236 13.0778 6.93611 13.1083 6.69167L13.7042 3.48333C13.7347 3.28472 13.834 3.113 14.0021 2.96817C14.1701 2.82333 14.3611 2.75061 14.575 2.75H18.2875C18.5625 2.75 18.7917 2.84167 18.975 3.025C19.1583 3.20833 19.25 3.4375 19.25 3.7125C19.25 5.62222 18.8338 7.50903 18.0015 9.37292C17.1692 11.2368 15.9888 12.9326 14.4604 14.4604C12.932 15.9882 11.2362 17.1686 9.37292 18.0015C7.50964 18.8344 5.62283 19.2506 3.7125 19.25Z"
        fill="#FFA800"
      />
    </svg>
  );
}
