export default function MenuIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.50001 17.27C4.35801 17.27 4.23934 17.2216 4.14401 17.125C4.04867 17.0283 4.00067 16.9093 4.00001 16.768C3.99934 16.6266 4.04734 16.508 4.14401 16.412C4.24067 16.316 4.35934 16.2683 4.50001 16.269H19.5C19.642 16.269 19.7607 16.317 19.856 16.413C19.952 16.509 20 16.628 20 16.77C20 16.912 19.952 17.0306 19.856 17.126C19.76 17.2213 19.6413 17.269 19.5 17.269L4.50001 17.27ZM4.50001 12.5C4.35801 12.5 4.23934 12.452 4.14401 12.356C4.04867 12.26 4.00067 12.141 4.00001 11.999C3.99934 11.857 4.04734 11.7383 4.14401 11.643C4.24067 11.5476 4.35934 11.5 4.50001 11.5H19.5C19.642 11.5 19.7607 11.548 19.856 11.644C19.9513 11.74 19.9993 11.859 20 12.001C20.0007 12.143 19.9527 12.2616 19.856 12.357C19.7593 12.4523 19.6407 12.5 19.5 12.5H4.50001ZM4.50001 7.72996C4.35801 7.72996 4.23934 7.68229 4.14401 7.58696C4.04801 7.49096 4.00001 7.37196 4.00001 7.22996C4.00001 7.08796 4.04801 6.96929 4.14401 6.87396C4.24001 6.77862 4.35867 6.73096 4.50001 6.73096H19.5C19.642 6.73096 19.7607 6.77896 19.856 6.87496C19.9513 6.97096 19.9993 7.08929 20 7.22996C20.0007 7.37062 19.9527 7.48929 19.856 7.58596C19.7593 7.68262 19.6407 7.73062 19.5 7.72996H4.50001Z"
        fill="white"
      />
    </svg>
  );
}
