export default function Check() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.36646 10.1L12.0165 4.45C12.1498 4.31667 12.3054 4.25 12.4831 4.25C12.6609 4.25 12.8165 4.31667 12.9498 4.45C13.0831 4.58333 13.1498 4.74178 13.1498 4.92533C13.1498 5.10889 13.0831 5.26711 12.9498 5.4L6.83313 11.5333C6.69979 11.6667 6.54424 11.7333 6.36646 11.7333C6.18868 11.7333 6.03313 11.6667 5.89979 11.5333L3.03313 8.66667C2.89979 8.53333 2.83579 8.37511 2.84113 8.192C2.84646 8.00889 2.91602 7.85044 3.04979 7.71667C3.18357 7.58289 3.34202 7.51622 3.52513 7.51667C3.70824 7.51711 3.86646 7.58378 3.99979 7.71667L6.36646 10.1Z"
        fill="#A0D911"
      />
    </svg>
  );
}
