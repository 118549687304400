export default function DownloadIcon({ color }: { color?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.9792C9.88893 12.9792 9.78476 12.962 9.68754 12.9275C9.59032 12.8931 9.50004 12.8339 9.41671 12.75L6.41671 9.75004C6.25004 9.58338 6.17004 9.38893 6.17671 9.16671C6.18337 8.94449 6.26337 8.75004 6.41671 8.58338C6.58337 8.41671 6.78143 8.33004 7.01087 8.32338C7.24032 8.31671 7.4381 8.39643 7.60421 8.56254L9.16671 10.125V4.16671C9.16671 3.9306 9.24671 3.73282 9.40671 3.57338C9.56671 3.41393 9.76448 3.33393 10 3.33338C10.2356 3.33282 10.4337 3.41282 10.5942 3.57338C10.7548 3.73393 10.8345 3.93171 10.8334 4.16671V10.125L12.3959 8.56254C12.5625 8.39588 12.7606 8.31588 12.99 8.32254C13.2195 8.32921 13.4173 8.41615 13.5834 8.58338C13.7362 8.75004 13.8162 8.94449 13.8234 9.16671C13.8306 9.38893 13.7506 9.58338 13.5834 9.75004L10.5834 12.75C10.5 12.8334 10.4098 12.8925 10.3125 12.9275C10.2153 12.9625 10.1112 12.9798 10 12.9792ZM5.00004 16.6667C4.54171 16.6667 4.14948 16.5037 3.82337 16.1775C3.49726 15.8514 3.33393 15.4589 3.33337 15V13.3334C3.33337 13.0973 3.41337 12.8995 3.57337 12.74C3.73337 12.5806 3.93115 12.5006 4.16671 12.5C4.40226 12.4995 4.60032 12.5795 4.76087 12.74C4.92143 12.9006 5.00115 13.0984 5.00004 13.3334V15H15V13.3334C15 13.0973 15.08 12.8995 15.24 12.74C15.4 12.5806 15.5978 12.5006 15.8334 12.5C16.0689 12.4995 16.267 12.5795 16.4275 12.74C16.5881 12.9006 16.6678 13.0984 16.6667 13.3334V15C16.6667 15.4584 16.5037 15.8509 16.1775 16.1775C15.8514 16.5042 15.4589 16.6673 15 16.6667H5.00004Z"
        fill={color || "#FFA800"}
      />
    </svg>
  );
}
