export default function CardIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 20C3.95 20 3.47933 19.8043 3.088 19.413C2.69667 19.0217 2.50067 18.5507 2.5 18V6C2.5 5.45 2.696 4.97933 3.088 4.588C3.48 4.19667 3.95067 4.00067 4.5 4H20.5C21.05 4 21.521 4.196 21.913 4.588C22.305 4.98 22.5007 5.45067 22.5 6V18C22.5 18.55 22.3043 19.021 21.913 19.413C21.5217 19.805 21.0507 20.0007 20.5 20H4.5ZM4.5 12H20.5V8H4.5V12Z"
        fill="#080B10"
      />
    </svg>
  );
}
