export default function KaspiIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.17282 21.7672C6.42514 16.572 4.93852 16.3943 4.93852 13.8735C4.93852 13.0332 5.60103 11.0214 5.69799 10.0438C5.92421 7.79773 5.36673 8.39561 7.00686 6.73124L7.10382 4.42051C7.92793 3.62872 8.33998 2.91772 9.34991 3.16819C9.96395 4.99415 8.80051 4.42051 8.6874 6.55349C9.37415 7.46647 9.83468 7.55535 10.3518 8.70263C11.0062 10.1569 10.5376 10.1408 11.7738 11.3608C13.5916 11.5708 14.6905 11.5789 15.9912 10.2701C16.1286 8.8723 15.3207 8.99349 16.1448 7.70886C16.7103 6.82819 17.2678 6.3515 18.3262 6.707C18.6736 8.08051 17.1143 8.36329 17.5991 9.89032C19.6917 10.1004 20.1764 7.21601 21.7762 7.07058C21.7762 10.1489 18.5605 10.5528 18.4878 13.0575C18.4393 14.6249 19.829 18.9393 21.2348 19.8604C22.471 17.9617 23.8041 15.8934 23.9576 13.5988C25.0322 -2.05924 4.3164 -4.91129 0.381689 8.79959C0.179702 9.5025 0.0585095 10.2135 0.00195312 10.9245V12.7424C0.349371 17.2507 3.12871 21.2097 5.17282 21.7672Z"
        fill="#ffffff"
      />
      <path
        d="M11.2325 23.8678C16.8316 24.5304 16.2418 22.8014 15.1349 16.4266C14.6986 13.9139 14.1896 12.5889 11.2083 12.8232C9.34997 15.0289 10.2872 19.3272 10.3518 21.2097C10.4003 22.6963 10.1498 22.7933 11.2325 23.8678ZM17.6718 22.3327C18.6091 22.2196 18.7706 22.0257 19.5301 21.4198C19.6513 20.1351 18.7706 18.0668 17.3971 17.6547C16.7508 18.899 16.9366 21.1127 17.6718 22.3327ZM8.44507 23.3992C8.44507 21.8318 8.63898 18.5919 7.83103 17.477C6.75646 17.7921 7.08772 18.7131 7.07964 20.6765C7.07156 22.1146 6.80494 23.2538 8.44507 23.3992Z"
        fill="#ffffff"
      />
    </svg>
  );
}
