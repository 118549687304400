import { useEffect } from "react";
import Header from "components/common/header";

export default function PaymentPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="flex flex-col gap-12 px-4 py-12 mt-16">
        <h3 className="text-[32px] font-semibold">Политика онлайн платежей</h3>
        <div className="flex flex-col gap-8 text-sm">
          <p className="font-bold uppercase">
            Платежи. Оплата банковской картой онлайн
          </p>
          <p>
            Наш сайт подключен к интернет-эквайрингу, и Вы можете оплатить
            Услугу банковской картой Visa или Mastercard. После подтверждения
            выбранного Товара либо услуги откроется защищенное окно с платежной
            страницей процессингового центра TipTop pay, где Вам необходимо
            ввести данные Вашей банковской карты. Для дополнительной
            аутентификации держателя карты используется протокол 3-D Secure.
            Если Ваш Банк-эмитент поддерживает данную технологию, Вы будете
            перенаправлены на его сервер для прохождения дополнительной
            идентификации. Информацию о правилах и методах дополнительной
            идентификации уточняйте в Банке, выдавшем Вам банковскую карту.
          </p>
          <p>
            Услуга онлайн-оплаты осуществляется в соответствии с правилами
            Международных платежных систем Visa и MasterCard на принципах
            соблюдения конфиденциальности и безопасности совершения платежа, для
            этого используются самые актуальные методы проверки, шифрования и
            передачи данных по закрытым каналам связи. Ввод данных банковской
            карты осуществляется в защищенном окне на платежной странице TipTop
            pay.
          </p>
          <p>
            В поля на платежной странице требуется ввести номер карты, имя
            владельца карты, срок действия карты, трёхзначный код безопасности
            (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные
            отображены на поверхности банковской карты.
          </p>
          <p>
            CVV2/ CVC2 — это трёхзначный код безопасности, находящийся на
            оборотной стороне карты.
          </p>
          <p>
            Далее в том же окне откроется страница Вашего банка-эмитента для
            ввода 3-D Secure кода. В случае, если у вас не настроен статичный
            3-D Secure, он будет отправлен на ваш номер телефона посредством
            SMS. Если 3-D Secure код к Вам не пришел, то следует обратится в ваш
            банк-эмитент.
          </p>
          <p>
            3-D Secure — это самая современная технология обеспечения
            безопасности платежей по картам в сети интернет. Позволяет
            однозначно идентифицировать подлинность держателя карты,
            осуществляющего операцию, и максимально снизить риск мошеннических
            операций по карте.
          </p>
        </div>
        <div className="flex flex-col gap-8 text-sm">
          <p className="font-bold uppercase">Гарантии безопасности</p>
          <p>
            Процессинговый центр TipTop pay защищает и обрабатывает данные Вашей
            банковской карты по стандарту безопасности PCI DSS 3.0. Передача
            информации в платежный шлюз происходит с применением технологии
            шифрования SSL. Дальнейшая передача информации происходит по
            закрытым банковским сетям, имеющим наивысший уровень надежности.
            TipTop pay не передает данные Вашей карты нам и иным третьим лицам.
            Для дополнительной аутентификации держателя карты используется
            протокол 3-D Secure.
          </p>
          <p>
            В случае, если у Вас есть вопросы по совершенному платежу, Вы можете
            обратиться в службу поддержки клиентов платежного сервиса по
            электронной почте support-kz@tiptoppay.inc
          </p>
        </div>
        <div className="flex flex-col gap-8 text-sm">
          <p className="font-bold uppercase">Безопасность онлайн-платежей</p>
          <p>
            Предоставляемая Вами персональная информация (имя, адрес, телефон,
            e-mail, номер кредитной карты) является конфиденциальной и не
            подлежит разглашению. Данные Вашей кредитной карты передаются только
            в зашифрованном виде и не сохраняются на нашем Web-сервере.
          </p>
          <p>
            Безопасность обработки Интернет-платежей гарантирует ТОО «TipTop Pay
            Kazakhstan». Все операции с платежными картами происходят в
            соответствии с требованиями VISA International, MasterCard и других
            платежных систем. При передаче информации используются
            специализированные технологии безопасности карточных
            онлайн-платежей, обработка данных ведется на безопасном
            высокотехнологичном сервере процессинговой компании.
          </p>
          <p>
            Оплата платежными картами безопасна, потому что:
            <ul className="list-disc list-inside">
              <li>
                Система авторизации гарантирует покупателю, что платежные
                реквизиты его платежной карты (номер, срок действия, CVV2/CVC2)
                не попадут в руки мошенников, так как эти данные не хранятся на
                сервере авторизации и не могут быть похищены.
              </li>
              <li>
                Покупатель вводит свои платежные данные непосредственно в
                системе авторизации CloudPayments, а не на сайте
                интернет-магазина, следовательно, платежные реквизиты карточки
                покупателя не будут доступны третьим лицам.
              </li>
            </ul>
          </p>
        </div>
        <div className="flex flex-col gap-8 text-sm">
          <p className="font-bold uppercase">Возврат денежных средств</p>
          <p>
            При проведении онлайн-оплаты посредством платежных карт не
            допускается возврат наличными денежными средствами. Порядок возврата
            регулируется правилами международных платежных систем:
            <ol className="list-decimal list-inside">
              <li>
                Потребитель вправе отказаться от товара в любое время до его
                передачи, после передачи товара отказ необходимо оформить в
                течение 14 дней;
              </li>
              <li>
                Возврат товара надлежащего качества возможен в случае, если
                сохранены его товарный вид, потребительские свойства, а также
                документ, подтверждающий факт и условия покупки указанного
                товара;
              </li>
              <li>
                Потребитель не вправе отказаться от товара надлежащего качества,
                имеющего индивидуально-определенные свойства, если указанный
                товар может быть использован исключительно приобретающим его
                человеком;
              </li>
              <li>
                При отказе от товара со стороны потребителя продавец должен
                вернуть ему денежную сумму, уплаченную потребителем, не позднее
                чем через десять дней со дня предъявления потребителем
                соответствующего требования.
              </li>
            </ol>
          </p>
          <p>
            Для возврата денежных средств на банковскую карту необходимо
            заполнить «Заявление о возврате денежных средств», которое
            высылается по требованию компанией на электронный адрес, и отправить
            его вместе с приложением копии документа, удостоверяющего личность,
            по адрес info@garyshker.com.
          </p>
          <p>
            Возврат денежных средств будет осуществлен на банковскую карту в
            течение ___ рабочего дня со дня получения «Заявление о возврате
            денежных средств» Компанией.
          </p>
          <p>
            Для возврата денежных средств по операциям, проведенным с ошибками,
            необходимо обратиться с письменным заявлением и приложением копии
            документа, удостоверяющего личность, и чеков/квитанций,
            подтверждающих ошибочное списание. Данное заявление необходимо
            направить по адресу info@garyshker.com.
          </p>
          <p>
            Сумма возврата будет равняться сумме покупки. Срок рассмотрения
            Заявления и возврата денежных средств начинает исчисляться с момента
            получения Компанией Заявления и рассчитывается в рабочих днях без
            учета праздников/выходных дней.
          </p>
        </div>
        <div className="flex flex-col gap-8 text-sm">
          <p className="font-bold uppercase">
            Случаи отказа в совершении платежа:
          </p>
          <ol className="list-disc list-inside">
            <li>
              банковская карта не предназначена для совершения платежей через
              интернет, о чем можно узнать, обратившись в Ваш Банк-эмитент;
            </li>
            <li>
              недостаточно средств для оплаты на банковской карте. Подробнее о
              наличии средств на платежной карте Вы можете узнать, обратившись в
              банк, выпустивший банковскую карту;
            </li>
            <li>данные банковской карты введены неверно;</li>
            <li>
              истек срок действия банковской карты. Срок действия карты, как
              правило, указан на лицевой стороне карты (это месяц и год, до
              которого действительна карта). Подробнее о сроке действия карты Вы
              можете узнать, обратившись в банк-эмитент.
            </li>
          </ol>
          <p>
            По вопросам оплаты с помощью банковской карты и иным вопросам,
            связанным с работой сайта, Вы можете обратиться по следующим
            телефонам: <span className="font-bold">+7 702 000 63 69</span>
          </p>
        </div>
      </section>
    </>
  );
}
