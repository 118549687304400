export default function MailIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66683 18.3333C3.16266 18.3333 2.73122 18.1539 2.3725 17.7952C2.01377 17.4365 1.83411 17.0047 1.8335 16.5V5.49996C1.8335 4.99579 2.01316 4.56435 2.3725 4.20563C2.73183 3.8469 3.16327 3.66724 3.66683 3.66663H18.3335C18.8377 3.66663 19.2694 3.84629 19.6287 4.20563C19.9881 4.56496 20.1674 4.9964 20.1668 5.49996V16.5C20.1668 17.0041 19.9875 17.4359 19.6287 17.7952C19.27 18.1545 18.8383 18.3339 18.3335 18.3333H3.66683ZM11.0002 11.7562C11.0766 11.7562 11.1569 11.7446 11.2412 11.7214C11.3256 11.6982 11.4056 11.6639 11.4814 11.6187L17.9668 7.56246C18.0891 7.48607 18.1807 7.39074 18.2418 7.27646C18.3029 7.16218 18.3335 7.03599 18.3335 6.89788C18.3335 6.59232 18.2036 6.36315 17.9439 6.21038C17.6842 6.0576 17.4168 6.06524 17.1418 6.23329L11.0002 10.0833L4.8585 6.23329C4.5835 6.06524 4.31613 6.06157 4.05641 6.22229C3.79669 6.38301 3.66683 6.60821 3.66683 6.89788C3.66683 7.05065 3.69738 7.18449 3.7585 7.29938C3.81961 7.41426 3.91127 7.50196 4.0335 7.56246L10.5189 11.6187C10.5953 11.6645 10.6757 11.6991 10.76 11.7223C10.8443 11.7455 10.9244 11.7568 11.0002 11.7562Z"
        fill="#FFA800"
      />
    </svg>
  );
}
